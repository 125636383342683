import PropTypes from "prop-types";
import { memo, useCallback, useState } from "react";
import "./shop-switcher.scss";
import IconArrowDown from "app/pages/.shared/static/icons/IconArrowDown";
import IconArrowUp from "app/pages/.shared/static/icons/IconArrowUp";
import { replaceShopInUrl } from "app/utils/urlDataExtractor";
import { sendTagOnChangeLanguage } from "app/utils/analytics";
import {
	flip,
	offset,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useInteractions,
} from "@floating-ui/react";

const ShopSwitcher = ({ currentShop = "", shops = [] }) => {
	const [isOpen, setIsOpen] = useState(false);

	const switchLanguage = useCallback(code => {
		const selectedShop = code;
		const redirectURL = replaceShopInUrl(window.location.href, currentShop, selectedShop);
		window.location = redirectURL;
		sendTagOnChangeLanguage(code.slice(0, 2));
	}, []);

	const { context, x, y, refs, strategy } = useFloating({
		placement: "bottom",
		strategy: "absolute",
		open: isOpen,
		onOpenChange: setIsOpen,
		middleware: [offset(10), shift(), flip()],
	});

	const click = useClick(context);
	const dismiss = useDismiss(context);

	const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss]);

	return (
		shops.length > 1 && (
			<div className="shop-switcher" ref={refs.setReference} {...getReferenceProps({})}>
				<div className="shop-switcher__button">
					<i className={`icon icon--flag-${currentShop}`} />
					{isOpen ? (
						<IconArrowUp height={16} width={16} />
					) : (
						<IconArrowDown height={16} width={16} />
					)}
				</div>
				{isOpen && (
					<div
						className="shop-switcher__body"
						ref={refs.setFloating}
						{...getFloatingProps({
							style: {
								position: strategy,
								left: x - 10 ?? "",
								top: y ?? "",
							},
						})}
					>
						<ul className="shop-list">
							{shops.map(({ code, label }) => {
								const handleLanguageClick = () => {
									switchLanguage(code);
								};

								return (
									<li
										key={code}
										className="shop-list__item"
										onClick={handleLanguageClick}
									>
										<i className={`icon icon--flag-${code}`} />
										{label}
									</li>
								);
							})}
						</ul>
					</div>
				)}
			</div>
		)
	);
};

ShopSwitcher.propTypes = {
	currentShop: PropTypes.string,
	shops: PropTypes.array,
};

export default memo(ShopSwitcher);
