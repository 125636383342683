import { memo } from "react";
import PropTypes from "prop-types";
import "./HomeHeader.scss";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import ShopSwitcher from "app/pages/Home/ShopSwitcher/ShopSwitcher";
import LogoBrandContainer from "app/pages/.shared/LogoBrand/LogoBrandContainer";
import RelativeLink from "app/pages/.shared/RelativeLink";

const HomeHeader = ({ fallbackRedirectUrl }) => {
	return (
		<header className="home-header">
			<section className="home-header__content">
				<div className="home-header__left" />
				<div className="home-header__brand">
					<RelativeLink to={{ pathname: fallbackRedirectUrl }}>
						<LogoBrandContainer />
					</RelativeLink>
				</div>
				<div className="home-header__shop">
					<ShopSwitcherContainer renderComponent={props => <ShopSwitcher {...props} />} />
				</div>
			</section>
		</header>
	);
};

HomeHeader.propTypes = {
	fallbackRedirectUrl: PropTypes.string,
};

export default memo(HomeHeader);
